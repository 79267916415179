@import "../../../../assets/sass/utils/variables";

.section-title {
  color: $c-secondary-50;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 15px;
}

.c-team {
  &__block--empty {
    display: none;
  }

  &__description {
    margin: 0;
    color: $c-secondary;
    font-size: 17px;
    line-height: 20px;
  }

  &__line-break {
    border: 0;
    color: $c-primary;
    background-color: $c-primary;
    height: 1px;
    margin: 46px 0 20px 0;
  }
}
