@import './src/assets/sass/utils/variables';

.c-auth-wrapper {
  background-color: $c-secondary-80;
  height: 100vh;
}

.c-auth {
  width: 704px;
  height: 704px;
  background-color: $c-secondary;
  box-shadow: rgba(0,0,0,0.22);
  border-radius: 10px;
  padding: 111px 169px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  &__title {
    margin-bottom: 8px;
    color: $c-primary;
    font-size: 17px;
    line-height: 28px;
    &--standalone {
      margin-bottom: 40px;
    }
  }

  &__text {
    color: $c-primary;
    font-size: 17px;
    line-height: 28px;
    &--mb-1 {
      margin-bottom: 10px;
    }
  }

  &__description {
    margin-top: 18px;
  }

  &__help {
    margin-top: 31px;
    &__link {
      font-size: 16px;
      line-height: 28px;
      color: $c-primary;
      border: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.c-login-form{
  margin-top: 40px;
  &__button {
    margin-top: 40px;
  }
}

.c-error-notification {
  position: fixed;
  top: 100px;
  right: 0;
  left: 0;
  z-index: 9999;
  margin: 0 auto;
  .bx--inline-notification {
    margin: 0 auto;
  }
}


