.bx {
  $self: &;

  &--form-item {
    margin-bottom: 15px;
  }

  &--label {
    margin-bottom: 7px;
    font-size: 15px;
    color: $c-primary-50;
  }
  &--text-input {
    $text-input: &;
    height: 48px;
    color: $c-primary-90;
    font-size: 17px;
    line-height: 48px;
    border-bottom: none;
    border-radius: 6px;
    outline: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $c-secondary-30;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $c-secondary-30;
    }
    &::-ms-input-placeholder {
      color: $c-secondary-30;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
    &__field-wrapper {
      &[data-invalid] {
        ~ #{$self}--form-requirement {
          color: $c-error;
        }
        #{$text-input}--invalid:not(:focus) {
          outline: none;
          border: 2px solid $c-error;
        }
      }
      #{$text-input}__invalid-icon {
        fill: $c-error;
      }
    }
  }

  &--text-area {
    color: $c-primary-90;
    font-size: 17px;
    border-bottom: none;
    border-radius: 6px;
    outline: none;
    resize: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $c-secondary-30;
      font-size: 17px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $c-secondary-30;
    }
    &::-ms-input-placeholder {
      color: $c-secondary-30;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &--btn {
    $btn: &;
    padding: 12px 15px;
    justify-content: center;
    min-width: 176px;
    font-size: 17px;
    line-height: 20px;
    border-radius: 6px;
    &:focus {
      color: inherit;
      border: none;
      box-shadow: none;
    }
    &[hidden] {
      display: none;
    }
    &--primary {
      color: $c-secondary;
      background-color: $c-primary-80;
      &:active {
        background-color: $c-primary-80;
      }
      &:hover,
      &:focus {
        color: $c-secondary;
        background-color: $c-primary-50;
      }
    }
    &--secondary {
      color: $c-primary-90;
      background-color: $c-secondary-80;
      &:active {
        background-color: $c-secondary-80;
      }
      &:hover,
      &:focus {
        color: $c-primary-90;
        background-color: $c-secondary-80;
      }
    }
  }

  &--modal {
    #{$self}--text-input {
      background-color: $c-secondary-80;
    }
  }
  &--modal-container {
    background-color: #fff;
    max-width: 835px;
    border-radius: 10px;
    #{$self}--modal-content {
      padding: 10px 40px 20px 40px;
    }
  }
  &--modal-header {
    padding: 40px 60px 0 40px;
  }
  &--modal-close {
    margin: 39px 40px 0 0;
    padding: 0;
    width: auto;
    height: auto;
    border: none;
    &__icon {
      width: 36px;
      height: 36px;
    }
  }

  &--overflow-menu {
    $self: &;
    min-width: 56px;
    height: 48px;
    border-radius: 6px;
    outline: none;
    &:focus {
      outline: none;
    }
    &--primary {
      background-color: $c-primary-80;
      &:hover {
        background-color: $c-primary-50;
      }
      &#{$self}--open {
        box-shadow: none !important;
        background-color: $c-primary-50 !important;
      }
      #{$self}__icon {
        fill: $c-secondary;
        &:hover,
        &:focus {
          fill: $c-secondary;
        }
      }
    }
    &--secondary {
      background-color: $c-secondary-80;
      &:hover {
        background-color: $c-secondary-50;
      }
      &#{$self}--open {
        box-shadow: none !important;
        background-color: $c-secondary-80 !important;
      }
      #{$self}__icon {
        fill: $c-primary-90;
        &:hover,
        &:focus {
          fill: $c-primary-90;
        }
      }
    }
  }

  &--overflow-menu-options {
    $self: &;
    width: 242px;
    border-radius: 6px;
    box-shadow: none;
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
    &:after {
      background-color: transparent;
    }
    &--primary {
      margin-top: 8px !important;
      background-color: $c-primary-50;
      #{$self}__option {
        &:hover {
          background-color: $c-primary-30;
        }
      }
    }
    &--secondary {
      margin-top: -8px !important;
      background-color: $c-secondary-80;
      #{$self}__option {
        &:hover {
          background-color: $c-secondary-50;
        }
      }
    }
    &__option {
      font-size: 17px;
      height: 48px !important;
      line-height: 48px;
      border-radius: 6px;
    }
    &__btn {
      padding: 0 16px;
      outline: none;
      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
  &--tabs--scrollable {
    .bx--tabs--scrollable__nav-item--selected {
      outline: none;
      .bx--tabs--scrollable__nav-link {
        color: $c-secondary;
        font-size: 17px;
        font-weight: 400;
        border-bottom: none;
        &:active, &:focus {
          outline: none;
          color: $c-secondary;
          font-size: 17px;
          font-weight: 400;
          border-bottom: none;
        }
      }
    }
    .bx--tabs--scrollable__nav-item--disabled {
      .bx--tabs--scrollable__nav-link {
        border-bottom: none;
      }
    }
    .bx--tabs--scrollable__nav-link {
      color: $c-secondary-50;
      font-size: 17px;
      font-weight: 400;
      border-bottom: none;
      outline: none;
      padding-left: 0;
      padding-right: 40px;
      width: 100%;
    }
    .bx--tabs--scrollable__nav-item {
      &:hover {
        .bx--tabs--scrollable__nav-link {
          outline: none;
          border-bottom: none;
          color: $c-secondary-50;
        }
      }
    }
  }
  &--modal-container {
    .bx--label {
      font-size: 16px;
      color: $c-primary-50;
    }
    .bx--text-input,
    .bx--text-area {
      color: $c-primary;
      background-color: $c-secondary-80;
      &[disabled] {
        color: $c-secondary-50;
      }
    }
  }
  &--text-area__wrapper[data-invalid] > .bx--text-area--invalid:not(:focus) {
    outline: none;
    border: 2px solid $c-error;
  }
  &--indication {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin: 0 0 2px 2px;
    background-color: $c-indication;
  }
}

.text-input {
  &__dark-bg {
    color: $c-secondary;
    background-color: $c-primary-80;
  }
}

.m-bottom-40 {
  margin-bottom: 40px;
}

.m-bottom-25 {
  margin-bottom: 25px;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.l-btn-wrap {
  .bx--btn {
    margin-right: 15px;
  }
}

