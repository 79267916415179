$c-primary: #000;
$c-primary-90: #161616;
$c-primary-80: #262626;
$c-primary-50: #525252;
$c-primary-30: #767676;
$c-secondary: #fff;
$c-secondary-90: #f7f6fb;
$c-secondary-80: #f4f4f4;
$c-secondary-50: #c6c6c6;
$c-secondary-30: #a8a8a8;
$c-link: #006BFF;
$c-indication: #248AFF;
$c-error: #ff1313;
