@import '../../../../assets/sass/utils/variables';

.c-details-form {
  .bx--label {
    color: $c-secondary-50;
  }
  .bx--file--label {
    margin-bottom: 7px;
    font-size: 15px;
    font-weight: 400;
    color: $c-secondary-50;
  }
  .size-label {
    @extend .bx--file--label;
    margin-bottom: 25px;
  }
  .bx--label-description, .bx--file-container {
    display: none;
  }
  .autocomplete-wrapper {
    margin-bottom: 15px;
    input {
      width: 100%;
      height: 48px;
      background-color: #262626;
      color: #fff;
      font-size: 17px;
      line-height: 48px;
      border: none;
      border-radius: 6px;
      outline: none;
      padding: 0 1rem;
    }
    .autocomplete-item {
      line-height: 48px;
      vertical-align: middle;
      background-color: #262626;
      font-size: 17px;
      color: #fff;
      padding: 0 1rem;
      &--highlighted {
        background-color: #525252;
      }
    }
  }
  .error-text {
    color: $c-error;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.34;
    letter-spacing: 0.32px;
    margin: 0.25rem 0 0;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .border-red {
    input {
      border: 2px solid #da1e28;
      border-radius: 6px;
    }
  }
  .button-wrapper {
    overflow: hidden;
    .c-details-form__button {
      float: right;
    }
  }
}

.fields-wrapper__two-columns {
  display: flex;
  justify-content: space-between;
  .fields-part {
    width: 100%;
    max-width: 48.5%;
  }
}
