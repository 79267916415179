@import '../../../../assets/sass/utils/variables';

.bx--text-area, .bx--text-input {
  &.disabled-input {
    background-color: $c-primary-80;
  }
}

.positions-wrapper {
  .position-title-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bx--text-input__field-wrapper {
    max-width: 366px;
    width: 100%;
  }
  .bx--text-area__wrapper {
    max-width: 757px;
    width: 100%;
  }
}
