@import './src/assets/sass/utils/variables';

.c-user {
  $self: &;
  display: flex;
  width: 362px;
  position: relative;

  &--account {
    width: 298px;
    #{$self}__name {
      color: $c-primary;
    }
  }

  &__label {
    font-size: 15px;
    line-height: 16px;
    color: $c-primary-50;
    margin: 10px 0 8px 0;

  }

  &__image {
    overflow: hidden;
    min-width: 64px;
    width: 64px;
    height: 64px;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    margin: 0 16px;
    max-width: 210px;
  }

  &__name {
    margin: 0;
    color: $c-secondary;
    font-size: 17px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__details {
    margin-top: 8px;
    display: inline-grid;
  }

  &__detail {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    color: $c-secondary-50;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__button-wrapper {
    margin-left: auto;
    width: 56px;
  }

  &__options-btn {
    min-width: 56px;
    height: 48px;
    transform: rotate(90deg);
    &__text {
      &--primary {
        color: $c-primary;
        &:hover {
          color: $c-primary;
        }
      }
      &--secondary {
        color: $c-secondary;
        &:hover {
          color: $c-secondary;
        }
      }
      &--error {
        color: $c-error;
        &:hover {
          color: $c-error;
        }
      }
    }
  }
}

.c-user {
  margin-bottom: 34px;
  margin-right: 32px;
}
