.l-list {
  $self: &;
  &--split {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    #{$self}__item {
      width: 50%;
      padding: 0 10px;
    }
  }
}

.c-profile {
  .img-wrapper {
    width: 108px;
    height: 108px;
    overflow: hidden;
    border-radius: 10px;
  }
  .img-responsive {
    width: 108px;
    height: 108px;
    object-fit: cover;
  }
  .bx--file--label {
    display: none;
  }
  .bx--file-container {
    display: none;
  }
}

.c-details-form {
  &__button {
    &--submit {
      position: absolute;
      right: 40px;
      bottom: 70px;
    }
  }
}