@import "../../../assets/sass/utils/variables";

.c-header {
  display: flex;
  justify-content: space-between;
}

.tabs-wrapper {
  margin-bottom: 25px
}

.c-project {
  overflow: hidden;
  height: calc(100vh - 114px);

  &__main-wrapper {
    height: calc(100%);
    box-sizing: content-box;
    overflow-y: scroll;
    width: 100%;
    padding-right: 17px;
  }
  .bx--indication {
    margin-left: 5px;
  }
}

.c-project__main {
  .bx--tab-content {
    padding: 1rem 0;
  }
}
