$feature-flags: (
        grid-columns-16: true,
);

$carbon--font-families: (
        'sans': (
                unquote("'Faro Trial', 'IBM Plex Sans', Arial, sans-serif"),
        ),
) !default;

@import 'carbon-components/scss/globals/scss/styles';
@import 'carbon-components/scss/globals/grid/_grid.scss';

@import './assets/sass/main';
