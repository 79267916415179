@import 'assets/sass/utils/variables';

.App {
  overflow: hidden;
  min-height: 100vh;
}

.bx--loading__stroke {
  stroke: $c-secondary-30;
}

.bx--loading-overlay {
  position: absolute;
  background-color: transparent;
}
