@import './src/assets/sass/utils/variables';

.c-home {
  display: flex;
  min-height: 100vh;
}

.c-menu {
  position: relative;
  min-width: 345px;
  padding: 43px 24px 120px 24px;

  .bx--indication {
    margin-left: 5px;
  }

  &__block {
    margin-top: 48px;
  }

  &__block ~ &__block {
    margin-top: 40px;
  }

  &__title {
    user-select: none;
    font-size: 32px;
    line-height: 49px;
    font-weight: 600;
  }

  &__item {
    cursor: pointer;
    margin-top: 16px;
    font-size: 17px;
    line-height: 20px;

    &__link {
      text-decoration: none;
      color: $c-primary-30;
      &--active {
        color: $c-link;
      }
      &--plus {
        color: $c-primary;
      }
    }
  }
  &__side-bar-title-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__new__project {
    cursor: pointer;
    font-size: 17px;
    line-height: 20px;
  }
  &__pagination {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    .bx--btn {
      padding: 0;
      width: 48px;
      height: 48px;
      min-width: auto;
      min-height: auto;
      border: 1px solid #e0e0e0;
      border-radius: 0;
      &[disabled] {
        background-color: $c-secondary-80;
      }
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  &__pagination-pages {
    margin-right: 10px;
    line-height: 48px;
  }
  .c-user__info {
    max-width: 145px;
  }
}

.c-main {
  width: 100%;
  background-color: $c-primary-90;
  padding: 47px 52px;
  position: relative;
}

.c-account {
  position: absolute;
  left: 24px;
  bottom: 0;
}
