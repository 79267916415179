@import './src/assets/sass/utils/variables';

.c-confirm {
  &__title {
    margin-bottom: 8px;
    color: $c-primary;
    font-size: 17px;
    line-height: 28px;
  }

  &__description {
    color: $c-primary;
    font-size: 17px;
    line-height: 28px;
    height: 28px;
  }

  &__buttons {
    margin-top: 40px;
  }

  &__button {
    float: right;
    &--cancel {
      margin-right: 14px;
    }
  }
}
