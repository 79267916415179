@import "./src/assets/sass/utils/variables";

.c-people {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 114px);

  &__description {
    margin: 0;
    color: $c-secondary;
    font-size: 17px;
    line-height: 20px;
  }

  &__add-btn {
    width: 188px;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__main-wrapper {
    height: calc(100% - 100px);
    box-sizing: content-box;
    overflow-y: scroll;
    width: 100%;
    padding-right: 17px;
    margin-top: 52px;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
  }

  &__search {
    width: 366px;
    position: absolute;
    top: 0;
    right: 0;

    &__input {
      background-color: $c-primary-80;
      color: $c-secondary-50;
    }
  }
}

.c-title {
  user-select: none;
  text-transform: capitalize;
  font-size: 40px;
  font-weight: 800;
  line-height: 44px;
  color: #fff;
}
